<template>
    <div class="iphone_box">
        <Nav/>
        <Bytetrade/>
        <Content/>
        <Project/>
        <ProjectLogo/>
        <Footer/>
    </div>
</template>
<style scoped>
.iphone_box {
    width: 100%;
    height: auto;
    overflow: hidden;
}
</style>
<script>
import '../../style/common.css'

import Nav from './nav.vue'
import Bytetrade from './bytetrade.vue'
import Content from './content.vue'
import Project from './project.vue'
import ProjectLogo from './project_logo.vue'
import Footer from './footer.vue'

export default {
  name: 'home',
  components: {
    Nav,
    Bytetrade,
    Content,
    Project,
    ProjectLogo,
    Footer,
  },
  
  setup() {
    
    return {
    }
  },
  

}
</script>
