<template>
  <div class="nav_box">
    <div class="nav_box_light text-focus-in-backgr"></div>
    <div class="nav_left slide-in-left"></div>
    <div class="nav_text">
      <p class="nav_text_ByteTrade slide-in-right">ByteTrade</p>
      <div class="nav_text_Users">
        <p class="nav_text_Users_p text-focus-in">Web3.0 Infrastructure<br/> Innovator and Builder to Return<br/> Data Ownership Back to Users.</p>
        <p class="nav_text_Users_fonst slide-in-left-text">Lab.</p>
      </div>
    </div>
    <div class="nav justify-end">
      <div class="nav_loge fade-in"></div>
      <ul class="nav_list">
        <!-- slide-in-blurred-top -->
        <li @click="skipTo(val.id)" 
        :class="{
          'nav_list_li':val.name,
        'slide-in-blurred-top':val.name === 'ByteTrade OS',
        'slide-in-blurred-top-ecy':val.name === 'Ecosytem',
        'slide-in-blurred-top-event':val.name === 'Event',
        'slide-in-blurred-top-con':val.name === 'Contact'
        }" 

        v-for="val in NavList" :key="val.id" :data-to="val.id"
        >
          {{ val.name }}
        </li>
      </ul>
    </div>
  </div>
</template>
<style scoped>
.nav_box {
  width: 100%;
  height: 1010PX;
  background: url("../../assets/nav-box.jpg") no-repeat;
  background-position: center;
  background-size:100% 1010px;
  position: relative;
  top: 0PX;
  left: 0PX;
  display: flex;
}
.nav_box_light {
  width: 1168PX;
  height: 1086PX;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../assets/light.png');
  position: absolute;
  top: 0PX;
  left: 120PX;
  z-index: 99;
}
.nav_text {
  position:absolute;
  right: 17%;
  top: 340PX;
  z-index: 9999;
}
.nav_text_ByteTrade {
  font-size: 148PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #FFFFFF;
  height: 174PX;
}
.nav_text_Users {
  display: flex;
  align-items: center;
  height: 148PX;
  
}
.nav_text_Users_p {
  font-size: 24PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #FFFFFF;
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
}
.nav_text_Users_fonst {
  font-size: 148PX;
  font-family: ClashDisplay-Semibold, ClashDisplay;
  font-weight: 600;
  color: #FFFFFF;
}
.nav_left {
  width: 900PX;
  height: 100%;
  background: url("../../assets/nav-left.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 0PX;
  top: 0PX;
  z-index: 999;
}

.nav {
  width: 1200PX;
  height: 60PX;
  align-items: center;
  position: absolute;
  top: 52PX;
  left: 50%;
  margin-left: -600PX;
  z-index: 99999;
}
.nav_loge {
  width: 169PX;
  height: 32PX;
  background: url("../../assets/byteteade\ logo.svg") no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: auto;
}
.nav_list {
  display: flex;
}
.nav_list_li {
  position: relative;
  margin-right: 64PX;
  font-size: 16PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  height: 30PX;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
}
.nav_list_li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  border-bottom: 2PX solid #fff;
  transition: 0.2s all linear;
}
.nav_list_li:hover::before {
  border-bottom: 2PX solid #fff;
  width: 100%;
  top: 0;
  left: 0;
  transition-delay: 0.1s;
}
</style>
<script>
import { ref } from "vue";

export default {
  name: "nav",
  components: {},

  setup() {
    let NavList = ref([
      {
        name:"ByteTrade OS",
        id:'toByteTradeOS'
      },
      {
        name:"Ecosytem",
        id:'toEcosytem'
      },
      {
        name:"Event",
        id:'toEvent'
      },
      {
        name:"Contact",
        id:'toContact'
      },
    ]);
    return {
      NavList,
    };
  },

  props: {
    msg: String,
  },

  methods: {
    skipTo (selector) {
      const navHeight = document.querySelector('.nav').offsetHeight
      const target = document.getElementById(selector)
      target.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo(0, target.offsetTop - navHeight)-160
    }
  }
};
</script>
