<template>
    <div class="footer_box" id="toContact">
        <div class="footer_auto">
            <div class="footer_logo footer_bakcgr"></div>
            <div class="footer_logo_bottom">
                <a href="https://twitter.com/BytetradeLab">
                    <div class="footer_logo_but footer_bakcgr footer_img"></div>
                </a>
                <a href="https://medium.com/@bytetradelab">
                    <div class="footer_logo_but footer_bakcgr footer_image"></div>
                </a>
            </div>
        </div>
    </div>
</template>
<style scoped>
.footer_bakcgr {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}
.footer_box {
    width: 100%;
    height: 163px;
    background: #EC4B3D;
}
.footer_auto {
    width: 344px;
    height: 100%;
    margin: 0 auto;
    padding-top: 44px;
}
.footer_logo {
    width: 127px;
    height: 24px;
    background-image: url('../../assets/iphone/logo_bottm.png');
    display: flex;
}
.footer_logo_but {
    width: 42px;
    height: 42px;
}
.footer_img {
    background-image: url('../../assets/iphone/trese.png');
}
.footer_image {
    background-image: url('../../assets/iphone/sou.png');
    margin-left: 24px;
}
.footer_logo_bottom {
    display: flex;
    margin-top: 41px;

}
</style>