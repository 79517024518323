<template>
    <div class="footer_box" id="toContact">
        <div class="footer_auto justify-end">
            <div class="footer_left backgr" @click="backTop"></div>
            <div class="footer_address">
                <a href="https://twitter.com/BytetradeLab">
                    <div class="footer_fockbook backgr"></div>
                </a>
                <a href="https://medium.com/@bytetradelab">
                    <div class="footer_addclone backgr"></div>
                </a>
                
            </div>
        </div>
    </div>
</template>
<style scoped>
.footer_box {
    width: 100%;
    height: 200PX;
    background: #EC4B3D;

}
.backgr {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}
.footer_auto {
    width: 1200PX;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    
}
.footer_left {
    width: 169PX;
    height: 32PX;
    margin-right: auto;
    background-image: url('../../assets/byteteade\ logo.svg');
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    cursor: pointer;
}
.footer_left:hover{
    transform: translateY(-10PX);
    -webkit-transform: translateY(-10PX);
    -moz-transform: translateY(-10PX);
    -o-transform: translateY(-10PX);
    -ms-transform: translateY(-10PX);
}
.footer_address {
    display: flex;
}
.footer_fockbook,.footer_addclone {
    width: 58PX;
    height: 58PX;
    cursor: pointer;
}

.footer_fockbook {
    background-image: url('../../assets/fackbook.png');
    margin-right: 38PX;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out
}
.footer_fockbook:hover{
    transform: translateY(-12PX);
    -webkit-transform: translateY(-12PX);
    -moz-transform: translateY(-12PX);
    -o-transform: translateY(-12PX);
    -ms-transform: translateY(-12PX);
    background-image: url('../../assets/fackbook_hover.png');
}

.footer_addclone {
    background-image: url('../../assets/clone.png');
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out
}
.footer_addclone:hover{
    transform: translateY(-12PX);
    -webkit-transform: translateY(-12PX);
    -moz-transform: translateY(-12PX);
    -o-transform: translateY(-12PX);
    -ms-transform: translateY(-12PX);
    background-image: url('../../assets/clone_hover.png');
}
</style>
<script>
// import { ref } from "vue";

export default {
  name: "footer",
  components: {},

  setup() {
    return {
    };
  },

  props: {
    msg: String,
  },
    mounted() {
        // window.addEventListener("scroll", this.showbtn, true);
    },
  methods: {
      // 回到顶部
      backTop() {
            var timer = setInterval(function () {
                let osTop = document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop = osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) { clearInterval(timer) }
            }, 10);
        },
  }
};
</script>