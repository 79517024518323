
import { createApp } from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible.js';
import axios from 'axios';
import moment from 'moment'


axios.interceptors.request.use(config => {
    config.headers['cache-Control'] = 'no-cache'
    return config
})

const app = createApp(App);
app.config.globalProperties.$httpAxios = axios;
app.config.globalProperties.$moment = moment;


app.mount('#app');

