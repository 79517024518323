<template>
    <div class="project_logo_box" id="toEcosytem">
        <div class="project_logo_auto">
            <div class="project_logo_Project">Ecosystem Partner</div>
            <div>
                <ul class="project_logo_ul">
                    <li v-for="Url in imgList" :key="Url.url" :class="Url.is_boder ? 'project_logo_li flex-col':'project_logo_li project_logo_border flex-col'">
                    <img v-if="Url.url" class="img" :src="Url.url" alt="">
                    </li>
                </ul>
            </div>
            <div class="project_logo_Project">Ecosystem Investor</div>
            <div>
                <ul class="project_logo_ul">
                    <li v-for="proj in projList" :key="proj.url" :class="proj.is_boder ? 'project_logo_li flex-col':'project_logo_li project_logo_border flex-col'">
                        <img v-if="proj.url"  class="img" :src="proj.url" alt="">
                    </li>
                </ul>
            </div>
            <div class="project_logo_Project" id="toEvent">Upcoming Event</div>
            <Events/>
        </div>
    </div>
</template>
<style scoped>
.project_logo_box {
    width: 100%;
}
.project_logo_auto {
    width: 344px;
    border-right: 2px solid #EFEFEF;
    border-left: 2px solid #EFEFEF;
    margin: 0 auto;
}
.project_logo_Project {
    width: 100%;
    height: 119px;
    border-bottom: 2px solid #EFEFEF;
    display: flex;
    align-items: center;
    padding-left: 14px;
    font-size: 32px;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 600;
    color: #333333;

}
.project_logo_ul {
    width: 100%;
    overflow: hidden;

}
.project_logo_li {
    width: 170px;
    height: 71px;
    border-bottom: 2px solid #EFEFEF;
    float: left;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;

}
.project_logo_ul {
    width: 100%;
}
.project_logo_border {
    border-right: 2px solid #EFEFEF;
}
.img {
    width: 100%;
    height: 41px;
}
</style>
<script>
import { ref } from "vue";
import Events from './events.vue'


export default {
  name: "nav",
  components: {
    Events
  },

  setup() {
    let imgList = ref([
        {
            url:require('../../assets/iphone/project_loge1.png'),
        },{
            url:require('../../assets/iphone/project_loge2.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge3.png'),
        },
        {
            url:require('../../assets/iphone/project_loge4.png'),
            is_boder:'no'
        },{
            url:require('../../assets/iphone/project_loge5.png'),
        },
        {
            url:require('../../assets/iphone/project_loge6.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge7.png'),
        },
        {
            url:require('../../assets/iphone/project_loge8.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge9.png'),
        },
        {
            url:require('../../assets/iphone/project_loge10.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge11.png'),
        },
        {
            url:require('../../assets/iphone/project_loge12.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge13.png'),
        },
        {
            url:require('../../assets/iphone/project_loge14.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge15.png'),
        },
        {
            url:require('../../assets/iphone/project_loge16.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/project_loge17.png'),
        },
        {
            is_boder:'no'
        },
    ]);
    let projList = ref([
        {
            url:require('../../assets/iphone/logo_list1.png'),
        },{
            url:require('../../assets/iphone/logo_list2.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/logo_list3.png'),
        },
        {
            url:require('../../assets/iphone/logo_list4.png'),
            is_boder:'no'
        },{
            url:require('../../assets/iphone/logo_list5.png'),
        },
        {
            url:require('../../assets/iphone/logo_list6.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/logo_list7.png'),
        },{
            url:require('../../assets/iphone/logo_list8.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/logo_list9.png'),
        },
        {
            url:require('../../assets/iphone/logo_list10.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/iphone/logo_list11.png'),
        },{
            url:require('../../assets/iphone/logo_list12.png'),
            is_boder:'no'
        },

    ]);
    return {
        imgList,
        projList,
    };
  },

  props: {
    msg: String,
  },
};
</script>