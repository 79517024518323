<template>
    {{isMenu}}
    <div :class="isMenu ? 'menu_box height':'fade-in-top'">
        <div class="menu_top">
          <div class="menu_backgr menu_logo"></div>
          <div @click="closeMebu" class="menu_backgr close_menu"></div>
        </div>
        <ul>
            <li :data-to="nav.id"  @click="skipTo(nav.id)" class="menu_nav" v-for="nav in NavList" :key="nav.id" :style="nav.name ==='Contact'? '':'border-bottom: 1px solid #EFEFEF;'">{{nav.name}}</li>
        </ul>
        <div class="menu_footer">
          <a href="https://twitter.com/BytetradeLab">
            <div class="menu_backgr menu_footer_emli"></div>
          </a>
          <a href="https://medium.com/@bytetradelab">
            <div class="menu_backgr menu_footer_link"></div>
          </a>
        </div>
    </div>
</template>
<style scoped>
.menu_box {
    width: 100%;
    background: #FFFFFF;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transition:all 0.3s ease;
    overflow: hidden;
}
.height {
  height: 452px;
}
.fade-in-top {
  width: 100%;
  transition:all 0.3s ease;
  height: 0px !important;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #FFFFFF;
}
.close_menu {
  font-size: 30px;
}
.menu_backgr {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.menu_top {
  width: 100%;
  height: 72px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;

}
.menu_logo {
  width: 127px;
  height: 24px;
  background-image: url('../../assets/iphone/muen_logo.png');
  margin-left: 20px;
}
.close_menu {
  width: 32px;
  height: 32px;
  background-image: url('../../assets/iphone/colse_muen.png');
  margin-left: auto;
  margin-right: 12px;
}
.menu_nav {
  width:100%;
  height: 72px;
  font-size: 16px;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #151515;
  line-height: 72px;
  padding-left: 20px;
}
.menu_footer {
  display: flex;
  width: 100%;
  padding-left: 20px;
}
.menu_footer_emli {
  width: 42px;
  height: 42px;
  background-image: url('../../assets/iphone/emli.png');
  margin-right: 22px;
}
.menu_footer_link {
  width: 42px;
  height: 42px;
  background-image: url('../../assets/iphone/link.png');
}
</style>
<script>
import { ref } from "vue";

export default {
  name: 'drop_down_menu',
  components: {
  },
  setup() {
    const isMenu = ref(false);
    let NavList = ref([
      {
        name:"ByteTrade OS",
        id:'toByteTradeOS'
      },
      {
        name:"Ecosytem",
        id:'toEcosytem'
      },
      {
        name:"Event",
        id:'toEvent'
      },
      {
        name:"Contact",
        id:'toContact'
      },
    ]);
    const handelClick = () => {
      isMenu.value = true;
    };
    const closeMebu = () =>{
      isMenu.value = false;
    }
    return {
        isMenu,
        handelClick,
        closeMebu,
        NavList
    }
  },
  methods: {
    skipTo (selector) {
      const navHeight = document.querySelector('.menu_box').offsetHeight
      const target = document.getElementById(selector)
      target.scrollIntoView({ behavior: 'smooth' })
      window.scrollTo(0, target.offsetTop - navHeight)-160
    }
  },
  props: {
  },

}
</script>