<template>
    <div class="contentBottom_box" id="toEcosytem">
        <div class="contentBottom_auto">
            <div class="contentBottom_Project">Ecosystem Partner</div>
            <div>
                <ul class="contentBottom_ul">
                    <li v-for="Url in imgList" :key="Url.url" :class="Url.is_boder ? 'contentBottom_li flex-col':'contentBottom_li contentBottom_border flex-col'">
                        <img v-if="Url.url" class="img" :src="Url.url" alt="">
                    </li>
                </ul>
            </div>
            <div class="contentBottom_Project">Ecosystem Investor</div>
            <div>
                <ul class="contentBottom_ul">
                    <li v-for="con in conList" :key="con.url" :class="con.is_boder ? 'contentBottom_li width_click flex-col':'contentBottom_li width_click contentBottom_border flex-col'">
                        <img class="img" :src="con.url" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<style scoped>
.contentBottom_box {
    width: 100%;
}
.contentBottom_auto {
    width: 1198PX;
    border-right: 2PX solid #EFEFEF;
    border-left: 2PX solid #EFEFEF;
    margin: 0 auto;
}
.contentBottom_Project {
    width: 100%;
    height: 125PX;
    border-bottom: 2PX solid #EFEFEF;
    display: flex;
    align-items: center;
    padding-left: 40PX;
    font-size: 44PX;
    font-family: Montserrat-SemiBold, Montserrat;
    font-weight: 700;
    color: #333333;

}
.contentBottom_ul {
    width: 100%;
    overflow: hidden;

}
.width_click {
    width:298PX !important;
}
.contentBottom_li {
    width: 298.5PX;
    height: 168PX;
    border-bottom: 2PX solid #EFEFEF;
    float: left;
    align-items: center;
    justify-content: center;
    justify-items: center;
    align-content: center;

}
.contentBottom_ul {
    width: 100%;
}
.contentBottom_border {
    border-right: 2PX solid #EFEFEF;
}
.img {
    width: 100%;
    height: 96PX;
}
</style>
<script>
import { ref } from "vue";

export default {
  name: "nav",
  components: {},

  setup() {
    let imgList = ref([
        {
            url:require('../../assets/content_img1.png'),
        },{
            url:require('../../assets/content_img2.png'),
        },
        {
            url:require('../../assets/content_img3.png'),
        },
        {
            url:require('../../assets/content_img4.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/content_img5.png'),
        },
        {
            url:require('../../assets/content_img6.png'),
        },
        {
            url:require('../../assets/content_img7.png'),
        },
        {
            url:require('../../assets/content_img8.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/content_img9.png'),
        },
        {
            url:require('../../assets/content_img10.png'),
        },
        {
            url:require('../../assets/content_img11.png'),
        },
        {
            url:require('../../assets/content_img12.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/content_img13.png'),
        },
        {
            url:require('../../assets/content_img14.png'),
        },
        {
            url:require('../../assets/content_img15.png'),
        },
        {
            url:require('../../assets/content_img16.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/content_img17.png'),
        },
        {
            name:0,
        },
        {
            name:0,
        },
        {
            name:0,
            is_boder:'no'
        },

    ]);
    let conList = ref([
        {
            url:require('../../assets/con13.png'),
        },{
            url:require('../../assets/con2.png'),
        },
        {
            url:require('../../assets/con3.png'),
        },
        {
            url:require('../../assets/con1.png'),
            is_boder:'no'
        },{
            url:require('../../assets/con4.png'),
        },
        {
            url:require('../../assets/con5.png'),
        },
        {
            url:require('../../assets/con11.png'),
        },{
            url:require('../../assets/con6.png'),
            is_boder:'no'
        },
        {
            url:require('../../assets/con10.png'),
        },
        {
            url:require('../../assets/con7.png'),
        },
        {
            url:require('../../assets/con12.png'),
        },{
            url:require('../../assets/con9.png'),
            is_boder:'no'
        },

    ]);
    return {
        imgList,
        conList,
    };
  },

  props: {
    msg: String,
  },
};
</script>