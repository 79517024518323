<template>
  <div class="content_box">
    <div class="content" >
         <div class="content_page justify-end" ref="RefDid">
          <div
            class="content_text justify-center"
            style="border-right: 2PX solid #EFEFEF;"
          >
            <div class="content_flex">
              <h4 :class="content_Animate ? 'content_h4 fade-in-bottom-text':'content_h4'">DID</h4>
              <h6 :class="content_Animate ?'content_h6 fade-in-bottom-text':'content_h6'">Personal Decentralized Identifier to Web3.0</h6>
              <p :class="content_Animate ? 'content_p fade-in-bottom-text':'content_p'">- DIDs for individuals, groups, DAOs, entities or others.</p>
              <p :class="content_Animate ? 'content_p fade-in-bottom-text':'content_p'">- Self generated and owned solely by the user. NO requirement for a centralized party.</p>
              <p :class="content_Animate ? 'content_p fade-in-bottom-text':'content_p'">
                - Easily searched for and found by in the network and therefore Nodes can establish direct communications.
              </p>
            </div>
          </div>
          <div class="content_img justify-center">
            <div :class="content_Animate ? 'content_img_box fade-in-backgr':'content_img_box'" style="background-color:#FB8E48">
              <div :class="content_Animate ? 'img slide-in-bottom-img img_hover':'img '"  :style="{ backgroundImage: 'url(' + imageDid + ')' }"></div>
            </div>
            
          </div>
        </div>
        <div class="content_page justify-end" ref="RefStorage">
          <div class="content_img justify-center">
            <div :class="Storage_Animate ? 'content_img_box fade-in-backgr':'content_img_box'" style="backgroundColor:#4829D4">
              <div :class="Storage_Animate ? 'img slide-in-bottom-img img_hover':'img'" :style="{ backgroundImage: 'url(' + imageStorage + ')' }"></div>
            </div>
          </div>
          <div
            class="content_text justify-center"
            style="border-left: 2PX solid #EFEFEF;"
          >
            <div class="content_flex">
              <h4 :class="Storage_Animate ? 'content_h4 fade-in-bottom-text':'content_h4'">Storage</h4>
              <h6 :class="Storage_Animate ?'content_h6 fade-in-bottom-text':'content_h6'">Storage Hub for Personal Private Data</h6>
              <p :class="Storage_Animate ? 'content_p fade-in-bottom-text':'content_p'">- Personal storage that is privately owned and accessible to users with private key only.</p>
              <p :class="Storage_Animate ? 'content_p fade-in-bottom-text':'content_p'">- Personal private data can be cached and saved in Node.</p>
              <p :class="Storage_Animate ? 'content_p fade-in-bottom-text':'content_p'">
                - Hold user's Verifiable Credentials to interact with real life Apps and DApps.
              </p>
            </div>
          </div>
        </div>
        <div class="content_page justify-end" ref="RefComputing">
          <div
            class="content_text justify-center"
            style="border-right: 2PX solid #EFEFEF;"
          >
            <div class="content_flex">
              <h4 :class="Computing_Animate ? 'content_h4 fade-in-bottom-text':'content_h4'">Computing</h4>
              <h6 :class="Computing_Animate ?'content_h6 fade-in-bottom-text':'content_h6'">Computer Kernel for Edge Computing</h6>
              <p :class="Computing_Animate ? 'content_p fade-in-bottom-text':'content_p'">-  Run Apps that require high computational power in a decentralized way.</p>
              <p :class="Computing_Animate ? 'content_p fade-in-bottom-text':'content_p'">
                - Node will conduct edge computing, operating in local environment with personal private data and interact with servers and blockchains.
              </p>
            </div>
          </div>
          <div class="content_img justify-center">
            <div :class="Computing_Animate ? 'content_img_box fade-in-backgr':'content_img_box'" style="background-color:#EB4A3D">
              <div :class="Computing_Animate ? 'img slide-in-bottom-img img_hover':'img '"  :style="{ backgroundImage: 'url(' + imageComputing + ')' }"></div>
            </div>
            
          </div>
        </div>
        <div class="content_page justify-end" ref="RefBlockchains">
          <div class="content_img justify-center">
            <div :class="Blockchains_Animate ? 'content_img_box fade-in-backgr':'content_img_box'" style="backgroundColor:#FEBC28">
              <div :class="Blockchains_Animate ? 'img slide-in-bottom-img img_hover':'img'"  :style="{ backgroundImage: 'url(' + imageBlockchains + ')' }"></div>
            </div>
          </div>
          <div
            class="content_text justify-center"
            style="border-left: 2PX solid #EFEFEF;"
          >
            <div class="content_flex">
              <h4 :class="Blockchains_Animate ? 'content_h4 fade-in-bottom-text':'content_h4'" class="content_h4">Blockchains</h4>
              <h6 :class="Blockchains_Animate ?'content_h6 fade-in-bottom-text':'content_h6'">Personal One-Pass-Node for Blockchains</h6>
              <p :class="Blockchains_Animate ? 'content_p fade-in-bottom-text':'content_p'">-  Support multiple blockchains and become multi-chain portal.</p>
              <p :class="Blockchains_Animate ? 'content_p fade-in-bottom-text':'content_p'">- Run on an open protocol and one can attest the other through blockchain-based DIDs.</p>
            </div>
          </div>
        </div>
      <div class="contrnt_paragraph">
        <span class="paragraph_5"
          >Peer-to-peer <br/>Instant Messaging</span
        >
        <!-- <span class="text_14">DID</span> -->
        <!-- <div class="block_14 flex-row justify-between"> -->
          
          <!-- <span class="text_15">Edge&nbsp;DApp</span> -->
        <!-- </div> -->
        <!-- <span class="text_16">Personal&nbsp;Data</span> -->
        <div class="block_15 flex-row justify-between">
          <div class="group_9 flex-col"></div>
          
          <div class="group_31 flex-col justify-between">
            <span class="text_17"
              >1.&nbsp;James&nbsp;sends&nbsp;a&nbsp;message&nbsp;to&nbsp;Edge&nbsp;Node</span
            >
            <div class="box_14 flex-col"></div>
            <span class="paragraph_6"
              >8.&nbsp;James‘s&nbsp;Edge&nbsp;Node&nbsp;sends&nbsp;<br />a&nbsp;response&nbsp;to&nbsp;James</span
            >
          </div>
          <div class="group_11 flex-col"></div>
          <div class="group_32 flex-col justify-between">
            <span class="paragraph_7"
              >
              2.&nbsp;James‘s&nbsp;Edge&nbsp;Node&nbsp;resolves&nbsp;<br />Ross‘s&nbsp;DID</span
            >
            <div class="box_15 flex-col"></div>
          </div>
        </div>
      </div>
      <div class="block_16 flex-row">
        <div class="image-text_4_postis">
            <div class="image-text_4 flex-row justify-between">
              <div class="group_8 flex-col"></div>
              <span class="text-group_4"><span class="span_cole">James’s</span>&nbsp;Client</span>
            </div>
          </div>
          <div class="node_edge">
            <p class="node_edge_p"><span class="node_edge_span">James’s</span>&nbsp;Edge Node</p>
            <div class="node_edge_sed">
              <div class="node_edge_img"></div>
              <ul class="node_edge_ul">
                <li>&nbsp;DID</li>
                <li>&nbsp;Edge DApp</li>
                <li>&nbsp;Personal Data</li>
              </ul>
            </div>
          </div>
        <div class="image-text_5 flex-row justify-between">
          <!-- <div class="group_13 flex-col"></div>
          <span class="text-group_5">Ross’s&nbsp;Client</span> -->
        </div>
        <div class="block_17 flex-col justify-between">
          <div class="section_12 flex-row justify-between">
            <span class="paragraph_8"
              >7.&nbsp;Ross’s&nbsp;Edge&nbsp;Node&nbsp;sends&nbsp;<br />a&nbsp;response&nbsp;to&nbsp;James’s&nbsp;Edge&nbsp;Node</span
            >
            <div class="box_18 flex-col"></div>
            <span class="paragraph_9"
              >3.&nbsp;Ross’s&nbsp;Edge&nbsp;Node&nbsp;<br />relays&nbsp;the&nbsp;message</span
            >
          </div>
          
        </div>
        <div class="image-text_6 flex-col justify-between">
          <div class="text-wrapper_3 flex-col">
            
          </div>
          <span class="text_19">Blockchains</span>
          <span class="text-group_6">ETH/SOL/APTOS/SUI/COSMOS</span>
        </div>
      </div>
      <div class="block_18 flex-row">
        <div class="group_14 flex-col"></div>
       
        <div class="box_62 flex-col">
          <span class="paragraph_10"
            >5.&nbsp;Ross’s&nbsp;Edge&nbsp;Node&nbsp;sends&nbsp;<br />a&nbsp;massage&nbsp;to&nbsp;Ross</span
          >
          <div class="block_2 flex-col"></div>
          <span class="text_20"
            >6.&nbsp;Ross’s&nbsp;sends&nbsp;a&nbsp;response&nbsp;to&nbsp;Ross’s&nbsp;Edge&nbsp;Node</span
          >
        </div>
        <div class="group_16 flex-col"></div>
        <div class="box_63 flex-col justify-between">
          <div class="box_20 flex-col"></div>
          <span class="paragraph_11"
            >
            4.&nbsp;Ross&nbsp;sends&nbsp;a&nbsp;response&nbsp;<br />to&nbsp;Ross’s&nbsp;Edge&nbsp;Node</span
          >
        </div>
        

      </div>
      <div class="text_18_box">
        <div class="divpoxs flex-row justify-between">
          <div class="group_13 flex-col"></div>
          <span class="text-group_5">&nbsp; <span style="color: #FB8F48;">Ross’s</span>&nbsp;Client</span>
        </div>
        <div class="node_edge">
            <p class="node_edge_p"><span style="color: #FB8F48;">Ross’s&nbsp;</span>Edge&nbsp;Node</p>
            <div class="node_edge_sed">
              <div class="node_edge_img"></div>
              <ul class="node_edge_ul">
                <li>&nbsp;DID</li>
                <li>&nbsp;Edge DApp</li>
                <li>&nbsp;Personal Data</li>
              </ul>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.content_flex {
  width: 510PX;
  height: 320PX;
}
.content_box {
  width: 100%;
  border-top: 2PX solid #efefef;
}
.content {
  border-left: 2PX solid #efefef;
  border-right: 2PX solid #efefef;
  width: 1200PX;
  margin: 0 auto;
  padding-bottom: 128PX;
}
.content_page {
  width: 100%;
  height: 414PX;
  border-bottom: 2PX solid #efefef;
  align-content: center;
}
.content_text {
  width: 600PX;
  height: 100%;
  align-items: center;
}
.content_img {
  width: 598PX;
  height: 100%;
  align-items: center;
}
.content_img_box {
  width: 552PX;
  height: 368PX;
}
.img {
  width: 552PX;
  height: 368PX;
  display: inline-block;
  background-position:center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  
}
.img_hover {
  transition: all 0.2s ease-in-out !important;
  -webkit-transition: all 0.2s ease-in-out !important;
  -moz-transition: all 0.2s ease-in-out !important;
  -o-transition: all 0.2s ease-in-out !important;
}
.img:hover {
  transform:scale(1.1) !important;
  -webkit-transform: scale(1.1) !important;
  -moz-transform: scale(1.1) !important;
  -o-transform: scale(1.1) !important;
  -ms-transform: scale(1.1) !important;
}
.content_h4 {
  font-size: 44PX;
  font-family: Montserrat-SemiBold, Montserrat;
  font-weight: 600;
  color: #333333;
}
.content_h6 {
  font-size: 24PX;
  font-family: Montserrat-Medium, Montserrat;
  font-weight: 500;
  color: #333333;
  margin-top: 8PX;
}
.content_p {
  font-size: 16PX;
  font-family: Montserrat-Regular, Montserrat;
  font-weight: 400;
  color: #333333;
  margin-top: 24PX;
}
.contrnt_paragraph {
  position: relative;
  overflow: hidden;
}
</style>
<script>
import { ref,onMounted,onBeforeUnmount} from "vue";
import { isElementNotInViewport } from "../../common/utils";

export default {
  name: "nav",
  components: {},

  setup() {
    let content_Animate = ref(false);
    let Storage_Animate = ref(false);
    let Computing_Animate = ref(false);
    let Blockchains_Animate = ref(false);
    let RefDid = ref(null);
    let RefStorage = ref(null);
    let RefComputing = ref(null);
    let RefBlockchains = ref(null);
    let imageDid = ref(require('../../assets/did.png'));
    let imageStorage = ref(require('../../assets/Storage.png'));
    let imageComputing = ref(require('../../assets/Computing.png'));
    let imageBlockchains = ref(require('../../assets/Blockchains.png'));
   
    const scrollToContent = () => {
        !isElementNotInViewport(RefDid.value) ? content_Animate.value = true : content_Animate.value = false;
    };
    const scrollToStorage = () => {
        !isElementNotInViewport(RefStorage.value) ? Storage_Animate.value = true : Storage_Animate.value = false;
    };
    const scrollToComputing = () => {
        !isElementNotInViewport(RefComputing.value) ? Computing_Animate.value = true : Computing_Animate.value = false;
    };
    const scrollToBlockchains = () => {
        !isElementNotInViewport(RefBlockchains.value) ? Blockchains_Animate.value = true : Blockchains_Animate.value = false;
    };
    onMounted(()=>{
        // 监听滚动事件
        window.addEventListener("scroll",scrollToContent);
        window.addEventListener("scroll",scrollToStorage);
        window.addEventListener("scroll",scrollToComputing);
        window.addEventListener("scroll",scrollToBlockchains);
    })
    onBeforeUnmount(()=>{
        //移除滚动事件
        window.removeEventListener('scroll',scrollToContent);
        window.removeEventListener('scroll',scrollToStorage);
        window.removeEventListener('scroll',scrollToComputing);
        window.removeEventListener('scroll',scrollToBlockchains);
    })
    return {
      RefDid,
      content_Animate,
      imageDid,
      imageStorage,
      imageComputing,
      imageBlockchains,
      RefStorage,
      RefComputing,
      RefBlockchains,
      Storage_Animate,
      Computing_Animate,
      Blockchains_Animate
    };
  },

  props: {
    msg: String,
  },
};
</script>
